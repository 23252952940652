

:root {
	--f-1_5: 1.5vw;
	--app-color1: #4b4d77;
	--app-color2: #9f9cb4;
	--text-white: #ffffff;
}


$primary: #4b4d77 ;


.hidden_color {
	font-size: 1.5vw;
	font-weight: bold;
	color: #9E9BB4;
}

.scrollbar {
	overflow-y: auto;
}

.scrollbar::-webkit-scrollbar {
	width: 8px;
	height: 6px;
	scroll-snap-align: end;
}

.scrollbar::-webkit-scrollbar-thumb {
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	background: rgba(0, 0, 0, 0.2);
}

/* custom colors  */
.scrollbar-primary::-webkit-scrollbar {
	background-color: #F5F5F5;
	border: 2px solid #4b4d77;
	border-radius: 25px;
}

.scrollbar-primary {
	scrollbar-color: #4b4d77 #f5f5f5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	background-color: #9494adff;
}

.my_categories_font {
	font-size: 1.5vw;
	font-weight: bold;
	color: #4B4C77 !important;
}

.hidden_color {
	font-size: 1.5vw;
	font-weight: bold;
	color: #9E9BB4;
}

.my_categories_form {
	background-color: #DEDCE3;
	border: 2px solid #3F3A58;
	border-radius: 3vw;
	padding: 0.6vw;
}

.hidden_color {
	font-size: 1.5vw;
	font-weight: bold;
	color: #9E9BB4;
}

.cmn-font {
	font-size: 1.6vw;
	color: #4b4d77;
}

.cmn-font-h2 {
	font-size: 3.5vw;
	color: #4b4d77;
	font-weight: bold;
}

.header button, input, optgroup, select, textarea {
	background: none;
	border: none;
	padding: 0rem 1rem;
    font-weight: bold;
    appearance: none;
}

@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/variables';
